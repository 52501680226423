@use "../../../../style/var";
@use "../../../../style/mixin";

.all_cards{
    background-color: var.$background-color;
    width: 90%;
    border-radius: 10px;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title_skills{
        font-size: 1.5rem;
        font-weight: bold;
        text-align: center;
        padding-top: 1rem;
        padding-bottom: 1rem;

        @include mixin.medium {
            padding-bottom: 3rem;
        }

        @include mixin.big {
            padding-bottom: 3rem;
        }
    }
    .cards_skills{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;
    
        @include mixin.medium {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-evenly;
        }
    
        @include mixin.big {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-evenly;
        }
    
        .card{
            background-color: var.$background-color;
            border-radius: 10px;
            border: 1px solid var.$xtra-soft-grey;
            margin-top: 2rem;
            padding: 2rem;
            width:90%;
            max-width: 26rem;
            display: flex;
            flex-direction: column;
    
            @include mixin.medium {
                width: 26rem;
                margin-right: 0.5rem;
                margin-top: 0.5rem;
            }
        
            @include mixin.big {
                width: 23rem;
                margin-right: 0.5rem;
                margin-top: 0.5rem;
            }
    
            .buttons{
                display: flex;
                justify-content: flex-end;
                margin-top: 20px;  
    
                button{
                    margin-left: 0.5rem; 
                    @include mixin.button_blue;             
                }
            }
        }
    }
        
}

