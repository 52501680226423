// @use '../../style/var';
// @use '../../style/mixin';


.star-rating {
    display: flex;
    width: 60px;
    // margin-bottom: 9px;

    .star {
        width: 15px;  /* Largeur des étoiles */
        height: 15px; /* Hauteur des étoiles */
        margin: 0 1px; /* Espacement entre les étoiles */
    }
    
    .star.filled {
        color: #ffc400; /* Couleur dorée pour les étoiles remplies */
    }  
    
    .star.half-filled {
        color: #ffd700; /* Couleur dorée pour les demi-étoiles */
    }

    .star.empty {
        color: #ccc; /* Couleur grise pour les étoiles vides */
    }
    
    .sr-only {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border: 0;
    }
    
}


