@use '../../style/var';
@use '../../style/mixin';


.home {
    .main{
    // pour centrer enfant pres_search et enfant join_button 
    // (bouton en bas de page)
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

        .pres_search{
            display: flex;
            flex-direction: column; 
            align-items: center;
            width: 100%;
            // pour ne pas être caché derrière la nav fixe
            margin-top: 130px;

            @include mixin.big {
                    // pour que le bloc soit directement 
                    // à la suite de la nav
                    margin-top: 80px;
            }
        }
        .presentation {
            width: 21rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 60px;
            color: var.$background-color;
            font-size: 1.7rem;
            font-weight: 500;
        
            @include mixin.big {
                display: flex;
                width: auto; 
                margin-top: 13rem;
                align-items: flex-start;
            }

            .border-display{
                margin-left: 1.85rem;  

                @include mixin.big {
                    margin-left: 1rem;  
                }
            }

            .display{
                background-color: var.$secondary-color;
                border: solid 1px var.$secondary-color;
                border-radius: 30px; 
                padding-left: 0.8rem;
                padding-bottom: 0.3rem;
                margin-right: 0.3rem;
            }
        }
        
        .join_button{
            display: flex;            
            background-color: var.$background-color;
            border-radius: 10px;
            padding: 0.5rem 0.7rem;
            margin-bottom: 2rem;
        }
    }
}

        


