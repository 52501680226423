@use "../../../style/var";
@use "../../../style/mixin";


.navLogged{
    
    position: fixed;
    top: 0;
    // left: 0;
    height: 81px; 
    display: flex;
    justify-content: space-between;
    background-color:var.$background-color;
    border-bottom: 1px solid rgb(151, 146, 146);
    width: 100%;


    button{
        background-color:var.$background-color;
        display: flex;
        align-items: center;

        .logo{
            margin-right :auto;
        }

        .logoIdea{
            width: 3rem;
            height: 3rem;
            margin-left: 0.7rem;
            margin-right: 0.2rem;
            margin-bottom: 5%;
        }

        .logoSkillswap{
            width: 10.5625rem;
            height: 4.5rem;
            margin-top: 0.4rem;
            margin-bottom: 0.1rem;         
        }
    }

    .logoUsers{
        display: flex;
        flex-direction: row;
        justify-content: end;
        align-items: center;
        margin-right: 0.7rem; 

        @include mixin.big {   
            width: 50%;
            justify-content: space-around;
        }


        .logo_dollar{
            display: flex;
            flex-direction: row;
            align-items: center;
            border-radius: 100%;
            border: solid 1px var.$xtra-dark-grey;
            background-color:var.$yellow_color;  
            padding: 0.3rem 0.6rem;   
            color:var.$xtra-dark-grey;
            margin-right: 5px;
        }

        .swappies{
            font-size: 1.4rem;
            font-weight: 700;
            margin-right: 4px;
            color:var.$xtra-dark-grey;
        }

        .icone{
            color:var.$xtra-dark-grey;
            height:1.6rem; 
        }

        .nav_desktop{
            height:1.6rem; 
            margin-top: 10px;
        }
        
        a {
            display: flex;
            flex-direction: column;
            align-items: center;
            color: var.$xtra-dark-grey;
            font-size: 0.7rem;
            
            @include mixin.small {   
                display: none;
            }
            @include mixin.medium {   
                display: none;
            }
            @include mixin.big {   
                display: flex;
            }
        }
                
        a:hover {
            color: var.$metal-blue;
        }      
    }
}

.nav_bottom {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: var.$background-color;
    overflow: hidden;
    display: flex;
    justify-content: space-around;
    padding: 14px 0;
    border-top: 1px solid var.$xtra-soft-grey;

    a {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: var.$xtra-dark-grey;
        font-size: 0.7rem;
    }
        
    a:hover {
        color: var.$metal-blue;
    }

    .icone_bottom{
        height: 1.5rem;
        padding-bottom: 3px;
    }


    @include mixin.big {   
        display: none;
    }
}
    
    