@use '../../../style/var';
@use '../../../style/mixin';

.password_login{
    margin-top: 10px;
    
    .pwd_and_button{
        display: flex;

        button{
            margin-left: 5px;
            @include mixin.small-button_grey;
        }
    }
}