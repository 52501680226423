@use '../../../../style/var';
@use '../../../../style/mixin';


.modif_skill{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .form{
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 3rem;
        padding: 2rem;
        background-color: var.$background-color;
        border-radius: 10px;
        margin-bottom: 2rem;

        
        @include mixin.medium {
            max-width: 30rem;
        }

        @include mixin.big {
            max-width: 30rem;
        }

        .fieldset{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;

            legend{
                font-size: 1.2rem;
                font-weight: bold;
                text-align: center;
                padding-bottom: 2rem;
            }

            label, input, select, textarea{
                width: 90%;
                padding: 0.5rem
            }

            input, textarea, select{
                margin-bottom: 1rem;
                border: 1px solid var.$xtra-soft-grey;
                border-radius: 10px;
                padding: 0.5rem
            }

            .description{
                height: 10rem;
            }
            
            .buttons{
                display: flex;
                width: 100%;
                justify-content: flex-end;

                .button_blue{
                    display: flex;
                    margin-top: 1rem;
                    margin-left: 1rem;
                    @include mixin.button_blue;
                }  
                
                .button_grey{
                    display: flex;
                    margin-top: 1rem;
                    @include mixin.button_grey;
                } 
            }
        }
    }
}






