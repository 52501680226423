@use "../../style/var";


.navHome{
    position: fixed;
    // top: 0;
    // left: 0;
    height: 81px; 
    display: flex;
    background-color:var.$background-color;
    border-bottom: 0.03125rem solid rgb(151, 146, 146);
    width: 100%;
    justify-content: space-between;

    button{
        background-color:var.$background-color;
        display: flex;
        align-items: center;

        .logo{
            margin-right :auto;
        }

        .logoIdea{
            width: 3rem;
            height: 3rem;
            margin-left: 0.7rem;
            margin-right: 0.2rem;
            margin-bottom: 5%;
        }

        .logoSkillswap{
           width: 10.5625rem;
            height: 4.5rem;
            margin-top: 0.4rem;
            margin-bottom: 0.1rem;         
        }
        
        .icone{
            margin-left: 0.5rem;
            // margin-right: 0.4rem;
            // height:27px; 
            color:var.$dark-grey-color;
            background-color:var.$background-color;

        }
    
        .iconeRegister{
            height:18px; 
            border: 0.13rem solid var.$dark-grey-color;
            padding: 0.5rem 0.4rem;
            border-radius: 100%;
        }

        .iconeLogin{
            height:2.37rem; 
        }
    }
}

.logoUsers{
    display: flex;
    flex-direction: row;
    // justify-content: end;
    margin-right: 0.7rem;
}
