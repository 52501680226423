@use "../../../style/var";
@use "../../../style/mixin";

.profile{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 7rem;
    padding-bottom: 10rem;

    .profile_skill{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        @include mixin.big {
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-evenly;
            align-items: flex-start;
        }
    }

    form{
        background-color: var.$background-color;
        width: 90%;
        border-radius: 10px;
        padding: 2rem;
        margin-bottom: 2rem;

        
        @include mixin.medium {
            width: 40%;
        }

        @include mixin.big {
            width: 40%;
            padding: 80px;
        }

        select{
            max-width: 200px;
            border: 1px solid var.$xtra-soft-grey;
            border-radius: 10px;
            padding: 0.6rem;
            margin-bottom: 0.7rem;
            font-size:1rem;
        }

        fieldset{
            display: flex;
            flex-direction: column;


            legend {
                text-align: center;
                padding-bottom: 2rem;
                font-size: 1.5rem;
                font-weight: bold;

                &.cat_choices{
                    padding-top: 2rem;
                    font-weight: 400;
                    font-size: 1.1rem;
                    text-align:left;
                    padding-bottom: 1rem;
                }
            }

            input{
                padding: 9px;
                border: solid 1px var.$xtra-soft-grey;
                border-radius: 10px;
                margin-bottom: 1rem;
                font-size:1rem;
            }

            textarea{
                border: 1px solid var.$xtra-soft-grey;
                border-radius: 10px;
                font-size:1rem;
            }

            button{
                display: flex;
                align-self: center;
                margin-top: 20px; 
                @include mixin.button_blue;
            }
        }
    }
}
    