@use "../../../style/var";
@use "../../../style/mixin";


.results{
    padding-top: 130px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 6rem;

    @include mixin.big {
        padding-top: 80px;
    }

}