@use '../../style/var';
@use '../../style/mixin';

.loading, .search-result{
    color: var.$xtra-dark-grey;
    text-align: center;
    margin-bottom: 10px;
}

.loading{
    color: var.$background-color;
}

.advertising{
        padding-top: 0px;

        @include mixin.big {   
            padding-top: 80px;
        }
    }

.cards_section{
    display: flex;
    justify-content: center;
    // width: 100%;
}


.allCards{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 90%;
    padding-top: 0px;
    // height: fit-content;
    padding-bottom: 80px;


    @include mixin.medium {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding-bottom: 80px;
    }

    @include mixin.big {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding-top: 0px;
        padding-bottom: 100px;
    }

    .card{
        display: flex;
        flex-direction: column;
        // justify-content: flex-start;
        padding: 10px 15px 13px 15px;
        // border: solid 1px var.$soft-grey-color;
        border-radius: 10px;
        background-color: var.$background-color;
        margin-top: 20px;
        // margin-right: 20px;
        // margin-left: 20px;
        width: 95%;
        height: 360px;
        max-width: 29rem;
        transition: box-shadow 0.3s ease;
        flex-shrink: 0;

        @include mixin.medium {
            margin-right: 20px;
            margin-left: 20px;
        }
        
        &:hover {
            box-shadow: 0 0 10px 5px rgb(207, 120, 5); 
            /* Effet de survol avec une couleur dégradée */
            // 0 decalage horizontal
            // 0 decalage vertical
            // 10 zone de portée de l'ombre
            // 5 zone de dégradé flou de l'ombre
        }

        h3{
            font-size: 1.1rem;
            width: 100%; // Ensure the title takes up the full width of its parent
            white-space: nowrap; // Prevent text from wrapping to the next line
            overflow: hidden; // Hide overflow content
            text-overflow: ellipsis; // Add ellipsis (...) for overflow text
            display: block; // Ensure the element is a block element
            
            @include mixin.medium {
                font-size: 1.3rem;
            }

            @include mixin.big {
                font-size: 1.3rem;
            }
        }

        @include mixin.medium {
            width: 40%;
            margin-top: 40px;
        }

        @include mixin.big {
            width: 26rem;
            margin-top: 30px;
            margin-right: 6px;
            margin-left: 6px;
        }
    
        img{
            width: 95px;
            height:95px;
        }
        
        .starsCatSubCat{
            display: flex;
            justify-content: space-between;

            .category{
                font-size: 1rem;
            }
        }
        .boxHeader{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;

            .childBoxHeader{
                height: min-content;
                color: var.$dark-grey-color;
                margin-left: 30px;
                padding-top: 5px;
                font-size: 1rem;

                .calendarIcone{
                    margin-right: 10px;
                    margin-left: 2px;
                    color: var.$icons_color;
                }
                .userIcone{
                    margin-right: 10px;
                    margin-left: 2px;
                    color: var.$icons_color;
                }
                .hatIcone{
                    margin-right: 6px;
                    color: var.$icons_color;
                }
                .levelIcone{
                    margin-right: 8px;
                    color: var.$icons_color;
                }
                .starsIcone{
                    margin-bottom: 30px;
                }
            }
        }
    
        .descrAndButton{
            display: flex;
            flex-direction: column;
            height: 200px;
            justify-content: space-between;
            margin-top: 10px;
        }

        .boxContent{
            margin-top: 0%;
            color: black;

            .description{
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3; // Number of lines to show
                overflow: hidden; // Hide any overflow text
                text-overflow: ellipsis; // Add ellipsis (...) to indicate overflow
                white-space: normal; // Allow wrapping
                line-height: 1.5; // Adjust line height if necessary
                margin: 0; // Remove default margin
            }
        }

        .boxButton{
            display: flex;
            justify-content: flex-end;

            .boxButton1{ 
                @include mixin.button_light_orange; 
            } 
            .boxButton2{ 
                margin-left: 10px;
                @include mixin.button_light_blue;
            } 
        }
    }
}



