@use '../../style/var';
@use '../../style/mixin';

.searchBar {
    display: flex;
    flex-direction: column;
    background-color: var.$background-color;
    border-radius: 15px;
    width: 21rem;
    padding-top: 0.5rem;
    margin-bottom: 20px;

    @include mixin.big {
        position: fixed;
        // top:100%;
        border-top: 1px solid var.$soft-grey-color;
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        border-radius: 0px;
        
    }


    .searchElement, .searchButton {
        border: 1px solid #ccc; 
        border-radius: 10px; 
        background-color: #fff;
        padding: 0.8rem; /* Ajouter du padding pour un espace interne */
        margin: auto;
        font-size: 1rem; 
        width: 90%; /* Assurer que le select occupe la largeur de son conteneur */
        box-sizing: border-box; /* Inclure padding et bordure dans le calcul de la largeur */
        
        @include mixin.big {
            display: flex; 
            width: 20%;
            margin-top: 0.5rem;
            width: 15rem;
        }
    }

    .searchElement {
        display: flex; 
        justify-content: center; /* Centrer horizontalement */
        align-items: center; /* Centrer verticalement */
        margin-top: 0.5rem;

        
    }

    .searchButton{
        background-color: var.$secondary-color;
        color: white;
        font-size: 1.5rem; 
        border: none;  
        height: 2.8rem;
        margin-bottom: 1rem;
        margin-top: 0.5rem;
        
        display: flex; 
        justify-content: center; /* Centrer horizontalement */
        align-items: center;
        align-self: center; /* centre l'élément parent = le bloc bleu */

    }
}



